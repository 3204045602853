export const GET_COMMUNITY_SUCCESS = "GET_COMMUNITY_SUCCESS";
export const GET_COMMUNITY_FAIL = "GET_COMMUNITY_FAIL";
export const GET_JOINED_COMMUNITIES_SUCCESS = "GET_JOINED_COMMUNITIES_SUCCESS";
export const GET_JOINED_COMMUNITIES_FAIL = "GET_JOINED_COMMUNITIES_FAIL";
export const GET_NOT_JOINED_COMMUNITIES_SUCCESS =
  "GET_NOT_JOINED_COMMUNITIES_SUCCESS";
export const GET_NOT_JOINED_COMMUNITIES_FAIL =
  "GET_NOT_JOINED_COMMUNITIES_FAIL";
export const JOIN_COMMUNITY_SUCCESS = "JOIN_COMMUNITY_SUCCESS";
export const JOIN_COMMUNITY_FAIL = "JOIN_COMMUNITY_FAIL";
export const LEAVE_COMMUNITY_SUCCESS = "LEAVE_COMMUNITY_SUCCESS";
export const LEAVE_COMMUNITY_FAIL = "LEAVE_COMMUNITY_FAIL";
export const REPORT_POST_SUCCESS = "REPORT_POST_SUCCESS";
export const REPORT_POST_FAIL = "REPORT_POST_FAIL";
export const GET_REPORTED_POSTS_SUCCESS = "GET_REPORTED_POSTS_SUCCESS";
export const GET_REPORTED_POSTS_FAIL = "GET_REPORTED_POSTS_FAIL";
export const DELETE_REPORTED_POST_SUCCESS = "DELETE_REPORTED_POST_SUCCESS";
export const DELETE_REPORTED_POST_FAIL = "DELETE_REPORTED_POST_FAIL";
export const GET_COMMUNITY_MEMBERS_SUCCESS = "GET_COMMUNITY_MEMBERS_SUCCESS";
export const GET_COMMUNITY_MEMBERS_FAIL = "GET_COMMUNITY_MEMBERS_FAIL";
export const GET_COMMUNITY_MODS_SUCCESS = "GET_COMMUNITY_MODS_SUCCESS";
export const GET_COMMUNITY_MODS_FAIL = "GET_COMMUNITY_MODS_FAIL";
export const BAN_USER_SUCCESS = "BAN_USER_SUCCESS";
export const BAN_USER_FAIL = "BAN_USER_FAIL";
export const UNBAN_USER_FAIL = "UNBAN_USER_FAIL";
