export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const GET_PUBLIC_USERS_SUCCESS = "GET_PUBLIC_USERS_SUCCESS";
export const GET_PUBLIC_USERS_FAIL = "GET_PUBLIC_USERS_FAIL";
export const GET_PUBLIC_USER_PROFILE_SUCCESS =
  "GET_PUBLIC_USER_PROFILE_SUCCESS";
export const GET_PUBLIC_USER_PROFILE_FAIL = "GET_PUBLIC_USER_PROFILE_FAIL";
export const CHANGE_FOLLOW_STATUS_SUCCESS = "CHANGE_FOLLOW_STATUS_SUCCESS";
export const CHANGE_FOLLOW_STATUS_FAIL = "CHANGE_FOLLOW_STATUS_FAIL";
export const GET_FOLLOWING_USERS_SUCCESS = "GET_FOLLOWING_USERS_SUCCESS";
export const GET_FOLLOWING_USERS_FAIL = "GET_FOLLOWING_USERS_FAIL";
