export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";

export const SIGN_IN_FAIL = "SIGN_IN_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_SERVICE_PREFERENCES_SUCCESS =
  "GET_SERVICE_PREFERENCES_SUCCESS";

export const GET_SERVICE_PREFERENCES_FAIL = "GET_SERVICE_PREFERENCES_FAIL";

export const UPDATE_SERVICE_PREFERENCES_SUCCESS =
  "UPDATE_SERVICE_PREFERENCES_SUCCESS";

export const UPDATE_SERVICE_PREFERENCES_FAIL =
  "UPDATE_SERVICE_PREFERENCES_FAIL";

export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";

export const GET_LOGS_FAIL = "GET_LOGS_FAIL";

export const DELETE_LOGS_SUCCESS = "DELETE_LOGS_SUCCESS";

export const DELETE_LOGS_FAIL = "DELETE_LOGS_FAIL";

export const GET_COMMUNITIES_SUCCESS = "GET_COMMUNITIES_SUCCESS";

export const GET_COMMUNITIES_FAIL = "GET_COMMUNITIES_FAIL";

export const GET_COMMUNITY_SUCCESS = "GET_COMMUNITY_SUCCESS";

export const GET_COMMUNITY_FAIL = "GET_COMMUNITY_FAIL";

export const GET_MODERATORS_SUCCESS = "GET_MODERATORS_SUCCESS";

export const GET_MODERATORS_FAIL = "GET_MODERATORS_FAIL";

export const ADD_MODERATOR_SUCCESS = "ADD_MODERATOR_SUCCESS";

export const ADD_MODERATOR_FAIL = "ADD_MODERATOR_FAIL";

export const REMOVE_MODERATOR_SUCCESS = "REMOVE_MODERATOR_SUCCESS";

export const REMOVE_MODERATOR_FAIL = "REMOVE_MODERATOR_FAIL";
